<template>
  <div class="bs-right-wrap">
    <ClientOnly>
      <div v-show="loading" class="bs-right-loading">
        <SkeletonRight />
      </div>
    </ClientOnly>
    <IScroll
      ref="iScrollRight"
      class="bs-right-scroll"
      :load-more-distance="80"
      :boundary-distance="50"
      :willChange="true"
      @touchstart="handleTouchStart"
      @onBoundary="handleBoundary"
    >
      <ul
        class="bs-cate-new-right"
        :data-title="activeLeftContent?.firstFloorContent?.props?.metaData?.categoryLanguage"
      >
        <li
          v-for="(cate, index) in list"
          :key="`${cate.cateId}_${index}`"
          class="bs-cate-entire-line"
          :class="{
            'border-bottom': true,
          }"
        >
          <CateTitle
            v-if="['FLOOR_COMPONENT'].includes(cate.styleType)"
            ref="cateTitle"
            :cate-type="cateType"
            :cate="cate"
            :activeChannel="activeChannel"
            :activeLeftContent="activeLeftContent"
            :comp-index="index"
            :handle-close="handleClose"
            :is-active="isActive"
            @clickItem="clickItem"
            @jumpLink="handeJump"
            @expose="handleExpose"
          />
          <CateImage
            v-if="['ONE_IMAGE_COMPONENT', 'TWO_IMAGE_COMPONENT'].includes(cate.styleType)"
            :cate="cate"
            :cate-type="cateType"
            :activeChannel="activeChannel"
            :activeLeftContent="activeLeftContent"
            :comp-index="index"
            :handle-close="handleClose"
            @clickItem="clickItem"
            @baseImageMounted="refreshLayout"
            @expose="handleExpose"
          />
          <CateSwiper
            v-if="['IMAGE_CAROUSEL_COMPONENT'].includes(cate.styleType) && cate.props.items.length > 0"
            :cate="cate"
            :cate-type="cateType"
            :activeChannel="activeChannel"
            :activeLeftContent="activeLeftContent"
            :comp-index="index"
            :index="index + 1"
            :handle-close="handleClose"
            @clickItem="clickItem"
            @expose="handleExpose"
          />
        </li>
        <div
          v-if="canNextPage.show"
          v-expose="{
            id: 'expose_turn_page_tip.comp_category',
            prefix: 'nextPagePrefix',
            delayReady: 2000,
            data: { item: {...activeLeftContent?.cate?.saInfo}, topNavSa: {...activeChannel?.channel?.saInfo} },
            callback: handleExpose
          }"
          class="cate-next-page"
        >
          <p>
            {{ canNextPage.text }}
          </p>
          <Icon name="sui_icon_more_circle_up_12px_2" size="17px" color="#ccc" />
        </div>
      </ul>
    </IScroll>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch, nextTick, defineExpose, inject, onActivated } from 'vue'
import ClientOnly from 'vue-client-only'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import IScroll from './scroll/index.vue'
import CateTitle from './CateTitleFunctional.vue'
import CateImage from './CateImage.vue'
import CateSwiper from './CateSwiper.vue'
import { Icon } from '@shein-aidc/icon-vue2'
import SkeletonRight from '../skeleton/right.vue'
import { getLink, jumpLink, getGenerateLLinkParams } from '../../../common/hooks/useLink.js'
import { mergeQueryString } from '@shein/common-function'
// import { stringifyQueryString } from '@shein/common-function'

const { vExpose } = inject('analysisInstance')

const emits = defineEmits(['clickCate','handleBoundary', 'markPoint', 'preload', 'notify', 'expose'])
const defaultImgIcon = ''
const SIDE_CONTAINER_DESIGN_WIDTH = 88
const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  list: {
    type: Array,
    default: () => [],
  },
  handleClose: {
    type: Function,
    default: () => { },
  },
  activeChannel: {
    type: Object,
    default: () => ({}),
  },
  activeLeftContent: {
    type: Object,
    default: () => ({}),
  },
  index: {
    type: Number,
    default: 0,
  },
  cateType: {
    type: String,
    default: 'sidecate',
  },
  getAnalysisParams: {
    type: Function,
    default: () => '',
  },
  canNextPage: {
    type: Object,
    default: () => ({}),
  },
  handleTouchStart:{
    type:Function,
    default:() => { }
  }
})

const isBrowser = typeof window !== 'undefined'
const UA = isBrowser ? window.navigator.userAgent.toLowerCase() : ''
const isIOS =
  !!(UA && /iphone|ipad|ipod|ios/.test(UA)) ||
  (isBrowser && window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1)
const isActive = ref(false)
onActivated(() => {
  if (!isIOS) return
  isActive.value = true
  // Safari上category切换至list再返回category时，文本省略号会不展示，以下代码触发强制重绘

  nextTick(() => {
    requestAnimationFrame(() => {
      isActive.value = false
    })
  })
})

const iScrollRight = ref(null)
watch(
  () => props.list,
  val => {
    refreshLayout(true)
  }
)

function refreshLayout(resetTranslate = true) {
  console.log('refresh right scroll...')
  nextTick(() => {
    iScrollRight.value?.refresh(resetTranslate)
  })
}

function handleBoundary({ direction }){
    if (!props.canNextPage.show) return
    if (direction !== 'ttb') return
    emits('handleBoundary')
}

const appConfigs = useAppConfigs()
const router = appConfigs.$router
function clickItem(item, params) {
  let url = getLink(item)
  // 拼接跳转埋点参数到url 上
  url = mergeQueryString({
    mergeObj: getGenerateLLinkParams(params),
    url,
  })
  if (item.isJump) {
    props.handleClose()
  }
  emits('notify',item)
  jumpLink({ url, hrefType: item?.hrefType }, router, emits)
}

function handeJump(url, options){
  emits('preload', url?.url, options.type)
  if(options.type === 'click'){
    emits('notify',{type: 'classify',...url})
    jumpLink(url, router, emits)
    if(options.closeDrawer){
      props.handleClose()
    }
  }
}

function handleExpose(data){
  emits('expose', data)
}

defineExpose({refreshLayout})

</script>
<style lang="less">
.bs-right-wrap {
  position: absolute;
  inset: 0;
  padding: 0;
  margin: 0;
  background-color: white;
  overflow: hidden;
  .bs-right-scroll,
  .bs-right-loading {
    position: absolute;
    inset: 0;
    padding: 0;
    margin: 0;
    background-color: white;
  }
  .bs-right-loading {
    z-index: 3;
  }
  .bs-cate-new-right {
    padding: 0 12px 12px;
    list-style: none;
  }
  .border-bottom {
    border-bottom: 1px solid #e5e5e5;
  }
  .cate-next-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vw;
    max-height: 108px;
    color: #ccc;
    text-align: center;
    font-size: 12px;
    p {
      line-height: 1.1667;
      margin-bottom: 3px;
    }
  }
}
</style>
