<script lang="jsx">
export default {
  name: 'SkeletonRight',
  functional: true,
  render(_, { props = {} }) {
    return (
      <ul class="bs-skeleton__right">
        {Array.from({ length: 2 }).map((item, index) => {
          return (
            <div style={index === 0 ? { 'padding-top': '12px' } : null}>
              <div class="bs-skeleton__right-title"></div>
              <ul class="bs-skeleton__right-classify-container">
                {Array.from({ length: 9 }).map(() => (
                  <li class="bs-skeleton__right-classify">
                    <div class="bs-skeleton__right-classify-img"></div>
                    <div class="bs-skeleton__right-classify-name"></div>
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </ul>
    )
  },
}
</script>

<style lang="less">
.bs-skeleton {
  &__right {
    width: 100%;
    height: 100%;
    padding: 0 12px 12px;
    overflow-y: hidden;
    background: #fff;
  }

  &__right-title {
    height: min(3.2vw, 17.28px);
    background: #f5f5f5;
    margin: 0 min(40vw, 216px) 8px 0;
  }

  &__right-classify-container {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    background: #fff;
  }

  &__right-classify {
    width: 33.33%;
    height: min(25.06vw, 135.33px);
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__right-classify-img {
    width: min(17.6vw, 95px);
    height: min(17.6vw, 95px);
    background: #f5f5f5;
    border-radius: 50%;
  }

  &__right-classify-name {
    width: min(17.6vw, 95px);
    height: min(3.2vw, 17.28px);
    margin-top: min(0.53vw, 3px);
    background: #f5f5f5;
  }
}
</style>
