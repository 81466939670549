<template>
  <div
    ref="container"
    class="s-scrollable-container"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
    @touchcancel="onTouchEnd"
  >
    <div 
      ref="content"
      :style="contentStyle"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import UseTouch from './useTouch'

export default {
  name: 'IScroll',
  props: {
    original: { // 是否使用原生滚动
      type: Boolean,
      default: false
    },
    transitionDuration: { // 动画过渡时间
      type: Number,
      default: 300
    },
    scrollbars: { // 是否显示滚动条
      type: Boolean,
      default: false
    },
    additionalX: {  // 超出边界后，最大可滑动距离
      type: Number,
      default: 300
    },
    sensitivity: { // 惯性滑动过程的持续时间，值越小，感知上阻力越大，可近似认为惯性滑动过程速度减为零所需的时间(ms)
      type: Number,
      default: 1000,
      validator(v) {
        return v > 0
      }
    },
    reBoundExponent: { // 超出边界后，回弹的指数
      type: Number,
      default: 10,
      validator(value) {
        return value > 0
      },
    },
    // 超过边界后，下一次滑动超过这个距离执行回调
    // 例如：超出边界后，再次滑动，如果滑动距离小于这个值，不执行回调，否则执行回调
    // 用于判断是否需要加载更多数据
    loadMoreDistance: {
      type: Number,
      default: 0,
    },

    disableTouch: { // 禁止滑动
      type: Boolean,
      default: false
    },

    willChange: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      scroll: () => {
        return {
          translateY: this.translateY,
          racewayDistanceHeight: this.racewayDistanceHeight,
          viewAreaHeight: this.viewAreaHeight,
          contentHeight: this.contentHeight
        }
      },
    }
  },
  data() {
    return {
      useTouch: null,      // UseTouch 实例
      openTransition: false,     // 是否开启过渡动画
      translateY: 0,       // 内容区域的偏移量
      contentHeight: 0,
      viewAreaHeight: 0,
    }
  },

  computed: {
    contentStyle() {
      const style = {
        transform: `translate3d(0px, ${this.translateY}px, 0px)`, 
        transitionDuration: `${!this.openTransition ? 0 : this.transitionDuration}ms`,
      }
      if (this.willChange) {
        Object.assign(style, {
          willChange: 'transform' // 优化滚动性能, 'will-change: xxx'写法不生效
        })
      }
      return style
    },
  },

  watch: {
    translateY() {
      this.$emit('translateYChange', this.translateY)
    }
  },

  mounted() {
    setTimeout(() => { // 如果页面1.5s内都没有触摸，就进行初始化
      this.init()
    }, 1500)
  },

  methods: {
    init() {
      if (this.disableTouch || this.original) return
      if (!this.useTouch) {
        this.updateHeight()
        this.useTouch = new UseTouch()
      }
    },

    onTouchStart(event) {
      if (this.original) {
        this.$emit('touchstart', event)
        return
      }
      
      if (this.disableTouch) return
      this.openTransition = false
      this.init()
      cancelAnimationFrame(this.inertiaFrame)
      this.useTouch.touchStart(event)

      this.$emit('touchstart', event)
    },

    onTouchMove(event) {
      if (this.original) {
        this.$emit('touchmove', event)
        return
      }

      if (this.disableTouch) return
      if (!this.useTouch) return
      this.useTouch.touchMove(event, ({ deltaY }) => {
        let outY = 0 
        if (this.translateY >= 0) {
          outY = this.translateY
        } else {
          let remainY = this.isUnderfill ? this.translateY : this.translateY + this.racewayDistanceHeight
          if (remainY < 0 ) {
            outY = remainY
          }
        }
        outY = Math.abs(outY) // 超出的距离，值越大，滑动越慢

        let slideDistance = deltaY
        if (outY > 0) {
          // (最大滑动距离 * 当前滑动距离)  / （可视化高度 + 当前超出的距离) 随着超出的距离越大，滑动的距离越小
          slideDistance = (this.additionalX * slideDistance) / (this.viewAreaHeight + outY)
        }
        this.translateY += slideDistance

        // this.$refs?.scrollBar?.showBar?.()
      })
    },

    onTouchEnd(event) {
      if (this.original) {
        this.$emit('touchend', event)
        return
      }
      if (this.disableTouch) return
      if (!this.useTouch) return
      event.preventDefault()
      const isClick = this.handleClick(event)
      if (isClick) return
      
      if (!this.isCateNewSwiperTouch(event)) {
        event.stopPropagation()
      }
    
      // 检查是否滚动到边界
      if (this.handleBoundary()) {
        cancelAnimationFrame(this.inertiaFrame)
        this.handleBoundaryAgain()
        return
      }

      this.useTouch.touchEnd(event, ({ speed }) => {
        this.speed = speed // 滑动速度
        this.acceleration = this.speed / this.sensitivity // 惯性滑动加速度;
        this.frameStartTime = Date.now() // 记录开始时间戳
        this.inertiaFrame = requestAnimationFrame(this.moveByInertia)
      })
    },

    handleClick(event) {
      if (!this.useTouch) return false
      const duration = event.timeStamp - this.useTouch.startTimeStamp
      const { pageX, pageY } = this.useTouch.getTouches(event)
      const distanceY = pageY - this.useTouch.startY
      const distanceX = pageX - this.useTouch.startX
      // 开始和结束的坐标差值小于20，且时间差小于300ms，认为是点击事件
      if (duration < 300 && (Math.abs(distanceY) + Math.abs(distanceX)) < 20) {
        const target = event.target
        if ( !(/(SELECT|INPUT|TEXTAREA)/i).test(target.tagName) ) {
          const evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window,
          })
          target.dispatchEvent(evt)
        }
        this.$emit('click', event)
        return true
      }
      return false
    },

    moveByInertia() {
      const frameEndTime = Date.now()
      const frameTime = frameEndTime - this.frameStartTime

      if (this.useTouch.getIsMoveTop()) { // 方向
        if (this.translateY <= -this.racewayDistanceHeight) {
          this.acceleration *=
            (this.reBoundExponent +
              Math.abs(this.translateY + this.racewayDistanceHeight)) /
            this.reBoundExponent
          this.speed = Math.min(this.speed - this.acceleration, 0) // 为避免减速过程过短，此处加速度没有乘上frameTime;
        } else {
          this.speed = Math.min(
            this.speed - this.acceleration * frameTime,
            0
          )
        }
      } else {
        if (this.translateY >= 0) {
          this.acceleration *=
            (this.reBoundExponent + this.translateY) / this.reBoundExponent
          this.speed = Math.max(this.speed - this.acceleration, 0)
        } else {
          this.speed = Math.max(
            this.speed - this.acceleration * frameTime,
            0
          )
        }
      }

      this.translateY += (this.speed * frameTime) / 2

      if (Math.abs(this.speed) <= 0.001) {
        this.handleBoundary() // 结束递归, 超过边界后 执行回弹
        cancelAnimationFrame(this.inertiaFrame)
        return
      }
      this.frameStartTime = frameEndTime // 当前时间给下一次开始
      this.inertiaFrame = requestAnimationFrame(this.moveByInertia) // 递归加速
    },

    /**
     * @description 检查是否滚动到边界
     * @returns {boolean} 是否滚动到边界
     * */ 
    handleBoundary() {
      let isBoundary = false
      this.touchEndOutInfo = {}
      const changeTranslate = ({ y, outY }) => {
        this.openTransition = true
        this.touchEndOutInfo = {
          outY,
          originalTranslateY: this.translateY
        }
        this.translateY = y
        isBoundary = true
      }

      if (this.isUnderfill) {
        const outY = this.translateY >= 0 ? this.translateY : Math.abs(this.translateY)
        changeTranslate({ y: 0, outY })
      } else if (this.translateY > 0) {
        changeTranslate({ y: 0, outY: this.translateY })
      } else {
        const remainY = this.translateY + this.racewayDistanceHeight
        if (remainY < 0) {
          changeTranslate({ y: -this.racewayDistanceHeight, outY: Math.abs(remainY) })
        }
      }
      
      return isBoundary
    },

    // 超过边界触发回调
    handleBoundaryAgain() {
      const endOutY = this.touchEndOutInfo.outY
      const originalTranslateY = this.touchEndOutInfo.originalTranslateY
      this.touchEndOutInfo = {}
      if (!this.loadMoreDistance || this.loadMoreDistance <= 0) return
      if (endOutY > 0 && endOutY < this.loadMoreDistance) return
      const direction = originalTranslateY < 0 ? 'ttb' : 'btt'
      this.$emit('onBoundary', { direction })
    },
    
    updateHeight() {
      this.viewAreaHeight = this.$refs?.container?.offsetHeight
      this.contentHeight = this.$refs?.content?.offsetHeight
      let racewayDistanceHeight = this.contentHeight - this.viewAreaHeight
      if (racewayDistanceHeight <= 0) {
        racewayDistanceHeight = this.viewAreaHeight
        // 标识内容区域高度小于可视化高度
        this.isUnderfill = true
      } else {
        this.isUnderfill = false
      }
      this.racewayDistanceHeight = racewayDistanceHeight

      return {
        viewAreaHeight: this.viewAreaHeight,
        contentHeight: this.contentHeight,
        racewayDistanceHeight: this.racewayDistanceHeight,
      }
    },

    /**
     * @description 更新内容区域高度
     * */ 
    onUpdate(resetTranslate = true) {
      if (!this.useTouch) {
        return 
      }
      cancelAnimationFrame(this.inertiaFrame)
      clearTimeout(this.scrollToTimer)
      this.openTransition = false
      if (resetTranslate) {
        this.translateY = 0
      }
      this.useTouch.reset()
      this.updateHeight()
    },

    /**
     * @description 刷新
     * @param {boolean} resetTranslate 是否重置偏移量
     * 
    */
    refresh(resetTranslate = true) {
      this.onUpdate(resetTranslate)
    },

    scrollTo({ y, time } = {}) {
      if (typeof y === 'undefined') {
        throw new Error('y is required')
      }
      if (!this.useTouch && !this.contentHeight) {
        return 
      }
      cancelAnimationFrame(this.inertiaFrame)

      if (this.isUnderfill) {
        this.translateY = 0
        return
      }

      if (time) {
        this.openTransition = true
        clearTimeout(this.scrollToTimer)
        this.scrollToTimer = setTimeout(() => {
          this.openTransition = false
        }, time)
      }
      if (y < 0) {
        y = 0
      } else if (y > this.racewayDistanceHeight) {
        y = this.racewayDistanceHeight // 超出边界
      }
      this.translateY = -y
    },
    isCateNewSwiperTouch(e) {
      return !!e.target?.parentElement?.parentElement?.classList?.value?.includes?.('cate-swiper-img')
    }
  }
}
</script>

<style lang="less">
.s-scrollable-container {
  overflow-y: hidden;
}
</style>
