<template>
  <div class="c-cate__entire-banner1">
    <BaseImg
      v-for="(imageItem, itemIndex) in cate.props.items"
      v-expose="{
        id: 'expose_second_category.comp_category',
        data: {
          item: imageItem,
          topNavSa: activeChannel?.channel?.saInfo,
          oneCateContent: activeLeftContent?.cate?.saInfo,
          cateType,
        },
        code: cateType,
        delayReady: 300,
        callback:(data) => emits('expose',data)
      }"
      v-tap="{
        id: 'click_second_category.comp_category',
        data: {
          item: imageItem,
          topNavSa: activeChannel?.channel?.saInfo,
          oneCateContent: activeLeftContent?.cate?.saInfo,
          cateType,
        },
      }"
      :key="itemIndex"
      ref="baseImageRef"
      fit="contain"
      class="j-cate-two"
      :placeholder="placeholderObj"
      :custom-ratio="getCustomRatio(imageItem)"
      :ratio="imageItem.image.ratio"
      :img-src="imageItem.image.src"
      :img-design-width="TWO_IMAGE_DESIGN_WIDTH"
      :img-data-exp="{}"
      :ada="imageItem.ada"
      :alt="imageItem.hrefTitle"
      :observer-image="false"
      @click="event => clickItem(event, imageItem)"
    />
  </div>
</template>
<script setup>
import { defineProps, defineEmits, inject } from 'vue'
import BaseImg from './BaseImg'
// import { mergeQueryString } from '@shein/common-function'
const emits = defineEmits(['clickItem', 'expose'])
const placeholderObj = { width: 332, height: 314 }
const TWO_IMAGE_DESIGN_WIDTH = 250

const { vTap, vExpose } = inject('analysisInstance')

const props = defineProps({
  cate: {
    type: Object,
    default: () => {},
  },
  activeChannel: {
    type: Object,
    default: () => ({}),
  },
  activeLeftContent: {
    type: Object,
    default: () => ({}),
  },
  cateType: {
    type: String,
    default: '',
  },
  handleClose: {
    type: Function,
    default: () => {},
  },
  compIndex: {
    type: Number,
    default: 0,
  },
})
const getCustomRatio = item => {
  const { width, height } = item.image
  return `${(height * 100) / width}%`
}
const clickItem = (event, imageItem) => {
  const params = {
    cate: imageItem,
    cateType: props.cateType,
    channel: props.activeChannel?.channel,
    channelIndex: props.activeChannel?.channelIndex,
    oneCateContent: props.activeLeftContent,
    compIndex: props.compIndex,
    oneCate: props.activeChannel?.oneCate,
  }
  emits(
    'clickItem',
    {
      type: 'image',
      item: imageItem?.categoryType,
      cateLinks: {},
      compIndex: props.compIndex,
      index: -1,
    },
    params,
  )
}
</script>

<style lang="less" scoped>
/* stylelint-disable selector-class-pattern, selector-max-specificity, selector-max-type  */
.c-cate__entire-banner1 {
  display: flex;
  width: 100%;
  .cate-banner-box {
    display: flex;
    align-items: center;
  }
  .base-img {
    margin-top: 3.2vw;
    margin-top: min(3.2vw, 17.28px);
    overflow: hidden;
  }
  img {
    width: 100%;
  }
}
// .c-new-category {
// }
</style>
