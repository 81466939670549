<template>
  <div>
    <SkeletonHeader v-if="loading" />
    <s-tab
      v-if="!loading"
      ref="tabRef"
      v-model="selectTab"
      type="auto"
      @change="handleTabChange"
    >
      <s-tab-item
        v-for="(item, index) in tabData"
        v-expose="{id:'expose_top_category.comp_category',data: item.saInfo, delayReady: 1500, callback:(data) => emits('expose',data)}"
        v-tap="{id:'click_top_category.comp_category','data':item.saInfo}"
        :id="item.id"
        :key="item.id"
        :aria-label="item.name"
        role="button"
        tabindex="0"
      >
        {{ item.name }}
      </s-tab-item>
    </s-tab>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch, inject } from 'vue'
import { Tab as STab, TabItem as STabItem } from '@shein/sui-mobile'
import SkeletonHeader from '../skeleton/header.vue'
// import useAnalysis from '../hooks/useAnalysis.js'

const {vTap, vExpose} = inject('analysisInstance')

const props = defineProps({
  activeKey: {
    type: String || Number,
    default: ''
  },
  tabData: {
    type: Array,
    default:() => ([])
  },
  getUserSaAbt: {
    type: Function,
    default: () => {}
  },
  loading:{
    type: Boolean,
    default: true
  }
})

// const { getTopNavSa } = useAnalysis(props.getUserSaAbt)

const emits = defineEmits(['change', 'expose'])

const selectTab = ref(props.activeKey)
const tabRef = ref(null)
watch(() =>  props.activeKey, (val) => {
  selectTab.value = val
},{immediate:true})

const handleTabChange = (data) => {
  console.log(data);
  if (!data.oldVal) return // 首次进入页面不触发
  let item, itemIndex
  props.tabData.forEach((i, index) => {
    if (i.id == data.val) {
      item = i
      itemIndex = index
    }
  })
  emits('change', {channel:item,index:itemIndex})

  // emits('change', { item, itemIndex, target: document.querySelector(`.j-c-content__tab-item-${data.val}`) })
}

function scrollToSelected(){
  if(tabRef.value){
    tabRef.value.scrollToSelected()
  }
}

defineExpose({scrollToSelected})
</script>
<script>
export default {
  name: 'TabHeader',
}
</script>
