<template>
  <div class="bs-slidecat">
    <IconNav v-if="showIcon" @click="showSlide" :color="color" :size="size" v-tap="{id:'click_navigation.comp_category'}" />
    <ClientOnly>
      <SDrawer
        :visible.sync="show_category"
        direction="ltr"
        custom-class="bs-aside-content close-visible"
        append-to-body
      >
        <div class="close-x" @click="closeDrawer">
          <Icon name="sui_icon_nav_close_24px" size="24px" color="white" />
        </div>
        <div class="bs-slidecat_main">
          <TabHeader
            ref="headerRef"
            :tabData="topNav"
            :loading="topLoading"
            :active-key="activeChannel?.channel?.id"
            @change="handleTabChange"
          />
          <div class="bs-slidecat_content" :class="{ top0: hasInitView && topNav.length <= 1 }">
            <Left
              class="bs-slidecat_content-one"
              :cateType="cateType"
              :loading="leftLoading"
              :list="leftContent?.contents"
              :activeChannel="activeChannel"
              @clickCate="changeCate"
            >
              <BSwichLangCurrency
                v-if="showLangs"
                :lang-txt="{
                  language: langs?.['SHEIN_KEY_PWA_15668'] || 'Change Language',
                  currency: langs?.['SHEIN_KEY_PWA_15669'] || 'Change Currency',
                }"
                @open-drawer="openDrawer"
              />
              <slot name="sheinInfo" :langs="langs" />
              <AppDownload :language="langs" />
            </Left>
          </div>
          <SideCatFooter :language="langs" :unReadChatNum="unReadChatNum" @goPage="(path) => emits('goPage',path)"/>
          <div class="bs-slidecat_content-two" :class="{ 'show-slide': showSilde }">
            <header class="bs-slidecat_content-header">
              <div class="header-back" @click="closeSlide">
                <Icon
                  name="sui_icon_nav_back_24px"
                  :is-rotate="cssRight"
                  size="24px"
                />
              </div>
              <span>
                {{ slideTitle }}
              </span>
            </header>
            <div class="bs-slidecat_content-two-content">
              <Right
                v-if="!showType"
                :list="rightContent.contents"
                :loading="rightLoading"
                :cateType="cateType"
                :activeChannel="activeChannel"
                :activeLeftContent="activeLeftContent"
                :handleClose="closeDrawer"
                @markPoint="(name, params) => emits('markPoint', name, params)"
                @preload="(url, type) => emits('preload', url, type)"
                @notify="notify"
              />
              <SwichList
                v-else
                :show-type="showType"
                :language-list="language_list"
                :currency-infos="currency_infos"
              />
            </div>
          </div>
        </div>
      </SDrawer>
    </ClientOnly>
  </div>
</template>

<script name="BSlideCategory" setup lang="ts">
// 数据源：
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { getSource } from '../../common/dataSource'
import type { AS_Category, DS_Category } from '../../types'
import ClientOnly from 'vue-client-only'
import {ref, watch, computed, defineExpose, defineEmits, provide} from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { Drawer as SDrawer } from '@shein/sui-mobile'
import TabHeader from './components/header.vue'
import Left from './components/Left.vue'
import Right from './components/Right.vue'
import IconNav from './icon.vue'
import SideCatFooter from './components/sidecat-footer.vue'
import useData from '../../common/hooks/useData.js'
import useAnalysis from '../../common/hooks/useAnalysis.js'
import AppDownload from './components/sidecat-app-download.vue'
import BSwichLangCurrency from './components/BSwichLangCurrency/index.vue'
import SwichList from './components/BSwichLangCurrency/SwichList.vue'
import { provideCutImg } from '../../common/hooks/useCutImg'
import {useAnalysis as useSdkAnalysis} from '../../common/analysisSource'

const cateType = 'slidecat'
const appConfigs = useAppConfigs()
const { cssRight } = appConfigs.$envs || {}
provideCutImg(appConfigs)
interface IProps {
  fsData?: DS_Category.FS_DATA
  dataSource?: DS_Category.DataSource
  analysisSource?: AS_Category.AnalysisSource
  analysisData?: any
  color?: string
  size?: string | number
  showLangs?: boolean
  hasTopBanner?: boolean
  showIcon? :boolean
  unReadChatNum?: number
  // footerAriaLabels? : any
}

const props = withDefaults(defineProps<IProps>(), {
  hasTopBanner: false,
  showLangs: true,
  showIcon: true,
  unReadChatNum: 0
})

const analysisInstance = useSdkAnalysis(props.analysisSource)
const { vTap } = analysisInstance
provide('analysisInstance', analysisInstance)

const emits = defineEmits(['markPoint', 'preload', 'goPage', 'notify'])
const apis = getSource(props.dataSource, appConfigs)

const show_category = ref(false)
const showSilde = ref(false)


const { getTopNavSa } = useAnalysis(props.analysisData?.getUserSaAbt)
const {
  topNav,
  topLoading,
  changeChannel,
  leftLoading,
  leftContent,
  rightContent,
  rightLoading,
  activeLeftContent,
  activeChannel,
  changeLeftContent,
  getTopNav,
  hasInitView
} = useData(apis, cateType, getTopNavSa)

function changeCate(item) {
  changeLeftContent(item)
  emits('notify','oneCate', item)
  showSilde.value = true
}

const langs = ref({})
async function getLangs() {
  try {
    const res = await apis.getLanguage()
    langs.value = res?.info?.result
  } catch (error) {
    console.log('error',error);
  }
}

// const unReadChatNum = ref(0)
// async function getUnReadNum() {
//   try {
//     const res = await apis.getUnReadNum()
//   } catch (error) {
//     console.log('error',error);
//   }
// }

const slideTitle = computed(() => {
  if (showType.value == 'language') return langs.value?.['SHEIN_KEY_PWA_15668'] || 'Change Language'
  if (showType.value == 'currency') return langs.value?.['SHEIN_KEY_PWA_15669'] || 'Change Currency'
  return activeLeftContent.value?.cate?.firstFloorContent?.props?.metaData?.categoryLanguage
})

const hasGetSlideCat = ref(false)
function showSlide() {
  if (!hasGetSlideCat.value) {
    getTopNav(false)
    getLangs()
    hasGetSlideCat.value = true
  }
  // getUnReadNum()
  show_category.value = true
}

const closeSlide = () => {
  showSilde.value = false
  showType.value = ''
}

// 语种和币种设置
const language_list = ref([])
const currency_infos = ref([])
const showType = ref('')
const openDrawer = ({ type, data }) => {
  showSilde.value = true
  showType.value = type
  if (type == 'language') language_list.value = data
  if (type == 'currency') currency_infos.value = data
}

const closeDrawer = () => {
  show_category.value = false
  console.log('close drawer')
}

const headerRef = ref<any>(null)
function scrollToSelected(){
  headerRef.value?.scrollToSelected()
}

function handleTabChange(data){
  changeChannel(data)
  emits('notify','channel', data)
}


function notify(data){
  emits('notify','twoCate', data)
}

defineExpose({ closeDrawer, refreshTopNav: () => getTopNav(true), scrollToSelected })

// 数据源：END

// 内置路由：
// appConfigs.$router.push({ path: `/somepath`, query: { test: 123 } })
// 内置路由：END
</script>

<style lang="less">
.bs-aside-content {
  width: 80vw;
  overflow: hidden;
  &.close-visible,
  .S-drawer__body {
    overflow: visible;
    width: 80vw;
    width: min(80vw, 432px);
  }
  .close-x {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 11.7vw;
    height: 11.7vw;
    width: min(11.7vw, 63.2px);
    height: min(11.7vw, 63.2px);
    text-align: center;
    background-color: black;
    position: absolute;
    top: 0;
    right: max(-11.7vw, -63.2px);
  }
}
.bs-slidecat {
  &_main {
    position: absolute;
    inset: 0;
    overflow: hidden;
    // border: 1px solid black;
  }
  &_content {
    // border: 1px solid red;
    position: absolute;
    top: 11.7vw;
    top: min(11.7vw, 63.2px);
    bottom: 13.86vw;
    bottom: min(13.86vw, 75px);
    left: 0;
    right: 0;
    z-index: 1;
    overflow: hidden;
    &.top0 {
      top: 0;
    }
    &-one {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #f6f6f6;
    }

    &-two {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #fff;
      transform: translateX(100%);
      z-index: 1; /* stylelint-disable-line declaration-property-value-blacklist */
      transition: transform 0.3s;
      &.show-slide {
        transform: translateX(0);
      }
    }
    &-header {
      padding: 0 10vw;
      padding: 0 min(10vw, 54px);
      background-color: #fff;
      border-bottom: 1px solid #e5e5e5;
      height: 11.7vw;
      line-height: 11.7vw;
      height: min(11.7vw, 63.18px);
      line-height: min(11.7vw, 63.18px);
      font-weight: 700;
      font-size: 14px;
      text-align: center;
      position: relative;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      .header-back {
        position: absolute;
        left: 3.2vw;
        left: min(3.2vw, 17.28px);
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
    &-two-content {
      position: absolute;
      top: 11.7vw;
      top: min(11.7vw, 63.18px);
      left: 0;
      width: 100%;
      overflow: hidden;
      bottom: 0;
    }
  }
}
</style>
