export default `
.swiper-pagination{
  position: absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  bottom: 10px;
  left:0;
  right:0;
  z-index:10;
}
.swiper-pagination .swiper-pagination-bullet-active{
  background: rgba(34,34,34,.8) !important;
  border: 1px solid hsla(0,0%,100%,.8);
  opacity: 1 !important;
}
.swiper-pagination .swiper-pagination-bullet{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(0, 0, 0);
  opacity:0.2;
  margin: 0 4px;
}`