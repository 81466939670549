<script lang="jsx">
export default {
  name: 'SkeletonHeader',
  functional: true,
  render() {
    return (
      <header class='c-skeleton__tab-wrap'>
        <a class='c-skeleton__tab-item'></a>
        <a class='c-skeleton__tab-item'></a>
        <a class='c-skeleton__tab-item'></a>
        <a class='c-skeleton__tab-item'></a>
      </header>
    )
  },
}
</script>

<style lang="less">
.c-skeleton {
  &__tab-wrap {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: min(11.733vw,64px);
    border-bottom: 1px solid #e5e5e5;
    background: #fff;
    overflow: hidden;
  }

  &__tab-item {
    height: min(5.2vw,28px);
    margin: 0 min(3.2vw,17.28px);
    background: #f5f5f5;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
  }
}
</style>
